// const desktopToMobile = {
//   1920: 425,
//   1440: 325,
// };

// const getWidth = (width) => {
//   if (window.innerWidth < window.innerHeight) {
//     return desktopToMobile[width];
//   }
//   return width;
// };

// const px2vw = (size, width = 1440) => `${(size / width) * 100}vw`;
const px2vw = (size) => `${size}px`;

export default px2vw;
