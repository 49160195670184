import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

const Home = React.lazy(() => import("../pages/Home"));
const EsqueciSenha = React.lazy(() => import("../pages/EsqueciSenha"));
const Login = React.lazy(() => import("../pages/Login"));
const RedefinirSenha = React.lazy(() => import("../pages/RedefinirSenha"));
const SistemaUffa = React.lazy(() => import("../pages/SistemaUffa"));
const Termos = React.lazy(() => import("../pages/TermoUso"));
const Politica = React.lazy(() => import("../pages/Politica"));
const FbLogin = React.lazy(() => import("../pages/FbLogin"));
const Admin = React.lazy(() => import("../pages/Admin"));
const Cadastro = React.lazy(() => import("../pages/HomeLp"));
const Entrar = React.lazy(() => import("../pages/AcessarLP"));

const CadastroCampanha = React.lazy(() => import("../shared/CadastroCampanha"));
const LoginAdmin = React.lazy(() => import("../shared/LoginAdmin"));
const CadastroVoucher = React.lazy(() => import("../shared/CadastroVoucher"));

const PrivateRoutes = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    sessionStorage.getItem("token") && sessionStorage.getItem("token") !== ''
      ?
      <Component {...props} />
      :
      <Redirect to={{ pathname: "/", state: { from: props.location } }} />
  )} />
)

const Routes = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/termosecondicoes" component={Termos} />
          <Route exact path="/politicadeprivacidade" component={Politica} />
          <Route exact path="/fb-login" component={FbLogin} />
          <Route exact path="/entrar" component={Login} />
          <Route exact path="/cadastro" component={Cadastro} />
          <Route exact path="/login" component={Entrar} />
          <Route exact path="/login/esqueci-senha" component={EsqueciSenha} />
          <Route exact path="/login/redefinir-senha" component={RedefinirSenha} />
          <Route exact path="/uffa" component={LoginAdmin} />
          <Route path="/admin" component={Admin} />
          <Route path="/campanha" component={CadastroCampanha} />
          <Route path="/voucher" component={CadastroVoucher} />
          <PrivateRoutes path="/main" component={SistemaUffa} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default Routes;