import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import { ThemeProvider } from "styled-components";
import "./style.scss";

import Routes from "./components/routes";
import Theme from "./assets/css/theme";
import NotificationProvider from './contexts/NotificationContext';
import { GoogleAuthProvider } from './contexts/GoogleContext';


const trackingId = "UA-169060633-1";

ReactGA.initialize(trackingId);

// const elemento = document.getElementById('root');

// ReactDOM.render(
//   <Routes />
// , elemento)

// class App extends Component {
//   render() {
//     return(<Routes />)
//   }
// }

const elemento = document.getElementById("root");

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <GoogleAuthProvider>
      <NotificationProvider>
        <Routes />
      </NotificationProvider>
    </ GoogleAuthProvider>
  </ThemeProvider >
  , elemento);
