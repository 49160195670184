import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from 'react-use-googlelogin';

const createContext = () => {
  const ctx = React.createContext(undefined);

  const useCtx = () => {
    const contextValue = useContext(ctx);

    if (contextValue === undefined)
      throw new Error('useCtx must be inside a Provider with a value');

    return contextValue;
  };
  const ret = [useCtx, ctx.Provider];
  return ret;
};

const [useGoogleAuth, AuthProvider] = createContext();

export const GoogleAuthProvider = ({ children }) => {
  const hookData = useGoogleLogin({
    clientId: '977253388147-v7ls0pfmdfii0corepdads2jucq4lfoa.apps.googleusercontent.com',
    redirectUri: 'https://portal-uffa-dev.azurewebsites.net/redirect/google',
    scope: 'https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/profile.agerange.read https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.emails.read https://www.googleapis.com/auth/user.phonenumbers.read',
    responseType: 'code',
  });

  return <AuthProvider value={hookData}>{children}</AuthProvider>;
};

GoogleAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export { useGoogleAuth };
