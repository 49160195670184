import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const ErrorBox = ({
  children,
  onTransitionEnd,
  timing,
  isToaster,
  isError,
}) => {
  const [animationError, setAnimationError] = React.useState('');

  React.useEffect(() => {
    setTimeout(() => {
      setAnimationError('in');
    }, 0);
    setTimeout(() => {
      setAnimationError('out');
    }, timing);
  }, []);

  return (
    <S.ErrorWrapper
      isToaster={isToaster}
      isError={isError}
      className={animationError}
      onTransitionEnd={animationError === 'out' && onTransitionEnd}
    >
      <S.Icon />
      <S.Content>{children}</S.Content>
    </S.ErrorWrapper>
  );
};
ErrorBox.propTypes = {
  children: PropTypes.node.isRequired,
  onTransitionEnd: PropTypes.func.isRequired,
  timing: PropTypes.number.isRequired,
  isToaster: PropTypes.bool,
  isError: PropTypes.bool,
};

ErrorBox.defaultProps = {
  isError: true,
  isToaster: false,
};

export default ErrorBox;
