import React from 'react';
import PropTypes from 'prop-types';
import AlertBox from '../../components/shared/alert-error';

const createContext = () => {
  const ctx = React.createContext(undefined);

  const NotificationCtx = () => {
    const contextValue = React.useContext(ctx);

    if (contextValue === undefined)
      throw new Error('notificationCtx must be inside a Provider with a value');

    return contextValue;
  };
  const ret = [NotificationCtx, ctx.Provider];
  return ret;
};

const [useNotification, Provider] = createContext();

const NotificationProvider = ({ children }) => {
  const [message, show] = React.useState({});
  const showError = (text) => show({ type: 'error', text });
  const showSuccess = (text) => show({ type: 'success', text });
  return (
    <Provider value={{ showSuccess, showError }}>
      {message.text && (
        <AlertBox
          isToaster
          isError={message.type !== 'success'}
          timing={4000}
          onTransitionEnd={() => show({})}
        >
          {message.text}
        </AlertBox>
      )}
      {children}
    </Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
export { useNotification };
