/* eslint-disable indent */
import { css } from 'styled-components';

export const breakpoints = {
  xs: { max: 767 },
  sm: { min: 768, max: 1023 },
  md: { min: 1024, max: 1439 },
  lg: { min: 1440 },
  'sm-up': { min: 425 },
  'sm-down': { max: 1023 },
  'md-up': { min: 1024 },
  'md-down': { max: 1439 },
};

const media = {
  xs: (styles) => css`
    @media only screen and (max-width: ${breakpoints.xs.max}px) {
      ${styles}
    }
  `,
  sm: (styles) => css`
    @media only screen and (min-width: ${breakpoints.sm
      .min}px) and (max-width: ${breakpoints.sm.max}px) {
      ${styles}
    }
  `,
  md: (styles) => css`
    @media only screen and (min-width: ${breakpoints.md
      .min}px) and (max-width: ${breakpoints.md.max}px) {
      ${styles}
    }
  `,
  lg: (styles) => css`
    @media only screen and (min-width: ${breakpoints.lg.min}px) {
      ${styles}
    }
  `,
  'sm-up': (styles) => css`
    @media only screen and (min-width: ${breakpoints['sm-up'].min}px) {
      ${styles}
    }
  `,
  'sm-down': (styles) => css`
    @media only screen and (max-width: ${breakpoints['sm-down'].max}px) {
      ${styles}
    }
  `,
  'md-up': (styles) => css`
    @media only screen and (min-width: ${breakpoints['md-up'].min}px) {
      ${styles}
    }
  `,
  'md-down': (styles) => css`
    @media only screen and (max-width: ${breakpoints['md-down'].max}px) {
      ${styles}
    }
  `,
};

const Theme = {
  colors: {
    white: '#FFFFFF',
    pink: '#FC1891',
    graylight: '#CDCBCD',
    gray: '#555555',
    graydark: '#373737',
    graydark2: '#676767',
    graymed: '#ECECEC',
    graymed2: '#AAAAAA',
    graydust: '#C4C4C4',
    silver: '#F4F4F4',
    yellow: '#FDB32B',
    red: '#FF5150',
    caribbeangreen: '#04D182',
    facebookBtn: '#3765A3',
    instagramBtn: '#FF5176',
    googleBtn: '#FFFFFF',
  },
  fsize: {
    global: {
      small: 16,
      medium: 18,
      large: 20,
    },
    paragraph: {
      micro: 12,
      small: 14,
      medium: 16,
      large: 20,
      extralarge: 32,
    },
    title: {
      micro: 16,
      small: 22,
      medium: 32,
      large: 48,
    },
  },
  font: {
    nunito: 'Nunito, sans-serif',
    roboto: 'Nunito, sans-serif',
  },
  media,
};

export default Theme;
